var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-3 pb-3",staticStyle:{"position":"relative"},style:(("border-bottom: 1px solid " + (_vm.dark ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)')))},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{class:[
          'text-decoration-none d-flex align-center route-link',
          { dark: _vm.dark } ],attrs:{"to":{
          name: _vm.AppSection.COUNTERPARTIES,
        }}},[_c('v-icon',{attrs:{"color":_vm.dark ? 'grey lighten-3' : 'grey darken-3',"size":"20"}},[_vm._v(" mdi-chevron-left ")]),_c('span',{class:[
            'ml-1 text-body-2 grey--text',
            _vm.dark ? 'text--lighten-3' : 'text--darken-3' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.goToList"))+" ")])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-h5 font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$lang( "accountingPortal.counterparties.viewCounterpartyTitle", _vm.counterpartyName ))+" ")])],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.currencyCode"))+": ")]),_vm._v(" "+_vm._s(_vm.currencyCode)+" ")]),(_vm.bankName)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.currentBank"))+": ")]),_vm._v(" "+_vm._s(_vm.bankName)+" ")]):_vm._e(),(_vm.currentNet)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.currentNet"))+": ")]),_vm._v(" "+_vm._s(_vm.currentNet)+" ")]):_vm._e(),_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.taxation.title"))+": ")]),_vm._v(" "+_vm._s(_vm.taxation)+" ")]),(_vm.vatNumber)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.vatNumber"))+": ")]),_vm._v(" ƒ "+_vm._s(_vm.vatNumber)+" ")]):_vm._e(),(_vm.address)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.address.title"))+": ")]),_vm._v(" "+_vm._s(_vm.address)+" ")]):_vm._e(),_c('v-col',{staticClass:"py-1",attrs:{"cols":"auto"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.websiteUrl"))+": ")]),_vm._v(" "+_vm._s(_vm.websiteUrl)+" ")]),(_vm.note)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('span',{class:[
          'text-subtitle-1',
          'font-weight-bold',
          _vm.dark ? 'white--text' : 'black--text' ]},[_vm._v(" "+_vm._s(_vm.$lang("accountingPortal.counterparties.note"))+": ")]),_vm._v(" "+_vm._s(_vm.note)+" "),(_vm.counterparty && _vm.counterparty.note.length > 100)?_c('span',{staticClass:"primary--text text-decoration-underline cursor-pointer",on:{"click":function($event){_vm.isShowMore = !_vm.isShowMore}}},[_vm._v(" "+_vm._s(_vm.isShowMore ? _vm.$lang("accountingPortal.counterparties.hideNote") : _vm.$lang("accountingPortal.counterparties.showMoreNote"))+" ")]):_vm._e()]):_vm._e()],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"12px","top":"12px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":!_vm.hasEditAccess,"link":""},on:{"click":function($event){return _vm.$emit('show-form', true)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang("btn.edit"))+" ")])],1),_c('v-list-item',{staticClass:"error--text",attrs:{"disabled":!_vm.hasDeleteAccess,"link":""},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$lang("btn.delete"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }