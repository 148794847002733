



























import { Component, Vue } from "vue-property-decorator";

@Component
export default class CounterpartyMenu extends Vue {
  readonly MENU_ITEMS = [
    {
      title: this.$lang("accountingPortal.counterparties.banks.title"),
      route: {
        name: "counterpartyBanks",
      },
    },
    {
      title: this.$lang("accountingPortal.counterparties.networks.title"),
      route: {
        name: "counterpartyNetworks",
      },
    },
    {
      title: this.$lang("accountingPortal.counterparties.netRevenue.title"),
      route: {
        name: "counterpartyNetRevenue",
      },
    },
    {
      title: this.$lang("accountingPortal.counterparties.thresholds.title"),
      route: {
        name: "thresholds",
      },
    },
  ];

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }
}
