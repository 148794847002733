

















































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

import CounterpartyHeader from "@/accounting-portal/components/counterparties/CounterpartyHeader.vue";
import CounterpartyMenu from "@/accounting-portal/components/counterparties/CounterpartyMenu.vue";
import CounterpartyForm from "@/accounting-portal/components/counterparties/CounterpartyForm.vue";
import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import CounterpartyResponseModel from "@/accounting-portal/models/counterparties/CounterpartyResponseModel";
import { AppSection, DictionaryType } from "@/shared/models";

@Component({
  components: {
    CounterpartyHeader,
    CounterpartyMenu,
    CounterpartyForm,
    ConfirmationDialog,
  },
})
export default class CounterpartyView extends mixins(AppSectionAccessMixin) {
  isSideBarVisible = false;
  isDeleteDialogVisible = false;
  editingItem: CounterpartyResponseModel | null = null;

  get appSection(): AppSection {
    return AppSection.COUNTERPARTIES;
  }

  get loading(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartyLoading;
  }

  get counterpartyId(): string {
    return this.$route.params.counterpartyId;
  }

  get counterparty(): CounterpartyResponseModel {
    return this.$store.state.counterpartyStore.counterparty;
  }

  get isCounterpartyDeleting(): boolean {
    return this.$store.state.counterpartyStore.isCounterpartyDeleting;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("accountingPortal.counterparties.title")
    );

    this.$store.dispatch("loadCounterpartyById", this.counterpartyId);
    this.$store.dispatch("loadDictionaries", {
      dictionaryTypes: [DictionaryType.CURRENCIES],
    });
  }

  destroyed() {
    this.$store.commit("clearDictionaries");
  }

  showForm(value: boolean) {
    this.isSideBarVisible = value;
    this.editingItem = CounterpartyResponseModel.of(this.counterparty);
  }

  async deleteCounterparty() {
    await this.$store.dispatch("deleteCounterparty", this.counterpartyId);
    this.isDeleteDialogVisible = false;
  }
}
